import React from "react";

import { Flex, FlexProps, Text } from "Atoms";
import { OrderProduct } from "Types";
import { formatFinancialNumbers } from "Utils";
import { useQoplaStore } from "Stores/qoplaStore";

type Props = {
    orderProduct: OrderProduct;
} & FlexProps;

export const ExpressCartContentDiscount: React.FC<Props> = ({ orderProduct, ...rest }) => {
    const { companyLocale } = useQoplaStore();
    const totalPriceBeforeDiscount = orderProduct?.totalPrice + (orderProduct?.discountValue ?? 0);

    return (
        <Flex
            height={"max-content"}
            direction={"column"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            mt={2}
            {...rest}
        >
            <Text fontSize={"md"} fontWeight={"600"} m={0} p={0} textDecoration={"line-through"}>
                {formatFinancialNumbers(totalPriceBeforeDiscount, companyLocale)}
            </Text>
            <Text
                fontSize="lg"
                fontWeight="600"
                color="red.500"
                m={0}
                p={0}
            >{`${formatFinancialNumbers(orderProduct?.totalPrice ?? 0, companyLocale)}`}</Text>
        </Flex>
    );
};
