import React, { useState, useEffect, useRef } from "react";

import { Box, Flex, Image, Fade, BaseBoxProps } from "Atoms";
import { PoweredByQoplaLogo } from "../../../../../customer/shared";
import { Languages, useLanguage } from "LanguageProvider";
import SweFlag from "../../../../../assets/images/sweden.png";
import EngFlag from "../../../../../assets/images/united-kingdom.png";

export const langAndImg = {
    [Languages.SV]: SweFlag,
    [Languages.EN]: EngFlag
};

const FlagImageWithFade: React.FC<{}> = ({}) => {
    const { userLanguage } = useLanguage();
    const [imgSrc, setImgSrc] = useState(langAndImg[userLanguage ?? Languages.SV]);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        setIsOpen(false);

        const timer = setTimeout(() => {
            setImgSrc(langAndImg[userLanguage ?? Languages.SV]);
            setIsOpen(true);
        }, 200);

        return () => clearTimeout(timer);
    }, [userLanguage, langAndImg]);

    return (
        <Fade
            in={isOpen}
            transition={{
                enter: {
                    duration: 0.4
                },
                exit: {
                    duration: 0.4
                }
            }}
        >
            <Image src={imgSrc} h="64px" w="64px" />
        </Fade>
    );
};

type Props = {
    expressLanguages: string;
    handleOpenSecretModal: () => void;
    isDarkMode?: boolean;
} & BaseBoxProps;

const CLICK_THRESHOLD = 4;

export const ExpressStartScreenFooter: React.FC<Props> = ({
    expressLanguages,
    handleOpenSecretModal,
    isDarkMode = false,
    ...rest
}) => {
    const { onUserLanguageChange, userLanguage } = useLanguage();
    const [clickCount, setClickCount] = useState(0);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const laguageList = expressLanguages.split("/");

    const isSingleLanguage = laguageList.length === 1;

    useEffect(() => {
        if (isSingleLanguage) {
            onUserLanguageChange(laguageList[0] as Languages);
        }
    }, [expressLanguages]);

    const handleClick = () => {
        setClickCount(prevCount => prevCount + 1);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setClickCount(0);
        }, 2000);

        if (clickCount === CLICK_THRESHOLD) {
            handleOpenSecretModal();

            setClickCount(0);
            clearTimeout(timeoutRef.current);
        }
    };

    const textStyles = {
        fontSize: "18px",
        cursor: "pointer",
        color: isDarkMode ? "white" : "expressTextColor"
    };

    return (
        <Flex
            as="footer"
            bg={isDarkMode ? "expressDarkGrey" : "white"}
            w={"100%"}
            h="132px"
            alignItems={"center"}
            justifyContent={isSingleLanguage ? "flex-end" : "space-between"}
            px={"64px"}
            {...rest}
        >
            {laguageList.length > 1 && (
                <Flex alignItems={"center"} justifyContent={"space-between"} w={"309px"}>
                    <FlagImageWithFade />
                    <Flex alignItems={"center"} justifyContent={"space-between"} w={"205px"}>
                        <Box
                            fontWeight={userLanguage === Languages.SV ? "600" : "500"}
                            onClick={() => {
                                onUserLanguageChange(Languages.SV);
                            }}
                            {...textStyles}
                        >
                            Svenska
                        </Box>

                        <Box {...textStyles}>|</Box>

                        <Box
                            fontWeight={userLanguage === Languages.EN ? "600" : "500"}
                            onClick={() => {
                                onUserLanguageChange(Languages.EN);
                            }}
                            {...textStyles}
                        >
                            English
                        </Box>
                    </Flex>
                </Flex>
            )}
            <Box onClick={handleClick}>
                <PoweredByQoplaLogo
                    logoStyles={{ fontFamily: "qopla !important", fontSize: "5xl" }}
                    textStyles={{ fontSize: "0.6rem", bottom: "3px", right: "2px" }}
                />
            </Box>
        </Flex>
    );
};
