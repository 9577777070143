import React, { useState } from "react";
import { FiTag } from "@react-icons/all-files/fi/FiTag";

import { Modal, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { modalNames, PosTypes } from "Constants";
import { getTextColor } from "Utils";
import { Languagekey, useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { Box, Flex, Input, Text } from "Atoms";
import { ExpressButton } from "../shared/ExpressButton";
import { useMothershipMutation } from "Hooks";
import {
    CAMPAIGN_DISCOUNT_VALIDATION_STATUS,
    CHECKOUT_DISCOUNT_VALIDATIONS,
    CartProduct,
    DISCOUNT_VALIDATION_STATUS,
    DiscountValidationResponse,
    EatingOption,
    GENERAL_DISCOUNT_VALIDATION_STATUS
} from "Types";
import { VALIDATE_ONLINE_DISCOUNT_WITH_STATUS } from "GraphQLMutations";
import { useQoplaStore } from "Stores/qoplaStore";
import { useOnlineStore } from "Stores/onlineStore";
import { useOrderWindowsStore } from "Stores/orderWindowsStore";
import { getDiscountedCartProductIds, removeBannedDiscountedProductIds } from "OnlineUtils";

type Props = {
    modalContent: {
        eatingOption: EatingOption;
        cartProducts: CartProduct[];
    };
    closeModal: (modal: string) => void;
};

/** If backend comes back with any of these, need to switch to another status for express */
const statusToSwitch = [
    GENERAL_DISCOUNT_VALIDATION_STATUS.EMAIL_ALREADY_USED,
    GENERAL_DISCOUNT_VALIDATION_STATUS.EMAIL_NOT_ALLOWED,
    GENERAL_DISCOUNT_VALIDATION_STATUS.EMAIL_ONLY_DISCOUNT,
    CAMPAIGN_DISCOUNT_VALIDATION_STATUS.CAMPAIGN_EMAIL_NOT_FOUND
] as DISCOUNT_VALIDATION_STATUS[];

export const ExpressDiscountModal: React.FC<Props> = ({ modalContent, closeModal }) => {
    const [discountCode, setDiscountCode] = useState("");
    const [validationStatus, setValidationStatus] = useState<DISCOUNT_VALIDATION_STATUS | null>(null);

    const { selectedShop } = useQoplaStore();
    const { getAlcoholRefProductIds } = useOnlineStore();
    const {
        CartManager: { setActiveOrderWindowDiscount }
    } = useOrderWindowsStore();

    const { translate } = useLanguage();
    const { eatingOption, cartProducts } = modalContent;

    const {
        colors: { expressPrimary, expressSecondary }
    } = useTheme();

    const [validateDiscountCode] = useMothershipMutation<{
        validateOnlineDiscountWithStatus: DiscountValidationResponse;
    }>(VALIDATE_ONLINE_DISCOUNT_WITH_STATUS);

    const onValidateDiscountCode = async () => {
        const { data } = await validateDiscountCode({
            variables: { discountCode, shopId: selectedShop?.id, eatingOption }
        });
        const { discount, validationStatus } = data?.validateOnlineDiscountWithStatus || {
            discount: null,
            validationStatus: GENERAL_DISCOUNT_VALIDATION_STATUS.NO_DISCOUNT_FOUND
        };

        if (!!discount) {
            const isEmailOnlyDiscount = (discount.oneEmailLimit ?? false) || (discount.emailOnlyDiscount ?? false);
            if (isEmailOnlyDiscount) {
                setValidationStatus(GENERAL_DISCOUNT_VALIDATION_STATUS.NO_DISCOUNT_FOUND);
            } else {
                /** This validation will need to be moved to the backend
                 * Will need to migrate the availableOn field from pos types to order category when that branch is merged
                 */
                if (discount.availableOn?.includes(PosTypes.EXPRESS)) {
                    const discountedCartProductIds = getDiscountedCartProductIds(cartProducts, discount);
                    if (!discountedCartProductIds.length) {
                        setValidationStatus(CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_PRODUCTS);
                    } else {
                        const alcoholRefProductIds = getAlcoholRefProductIds();

                        const discountIdsWithRemovedAlcoholIds = removeBannedDiscountedProductIds(
                            cartProducts,
                            alcoholRefProductIds,
                            discountedCartProductIds
                        );

                        if (!discountIdsWithRemovedAlcoholIds.length) {
                            setValidationStatus(CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_ALCOHOL);
                        } else {
                            setActiveOrderWindowDiscount(discount, discountIdsWithRemovedAlcoholIds);
                            closeModal(modalNames.EXPRESS_DISCOUNT_MODAL);
                        }
                    }
                } else {
                    setValidationStatus(GENERAL_DISCOUNT_VALIDATION_STATUS.NO_DISCOUNT_FOUND);
                }
            }
        } else {
            const updatedStatus = statusToSwitch.includes(validationStatus)
                ? GENERAL_DISCOUNT_VALIDATION_STATUS.NO_DISCOUNT_FOUND
                : validationStatus;
            setValidationStatus(updatedStatus);
        }
    };

    const textColourWithPrimary = getTextColor(expressPrimary);
    const textColourWithSecondary = getTextColor(expressSecondary);

    return (
        <Modal open={true} placement="center" size="xl" borderRadius={"1.25rem"}>
            <ModalHeader>
                <ModalCloseBtn
                    onClick={() => closeModal(modalNames.EXPRESS_DISCOUNT_MODAL)}
                    bg={expressPrimary}
                    h={"32px"}
                    w={"32px"}
                    borderRadius={"100%"}
                    color={getTextColor(expressPrimary)}
                    top={"24px"}
                    right={"24px"}
                />
            </ModalHeader>
            <ModalBody w={"100%"} h={"300px"} p={16} m="auto">
                <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"} mb={6}>
                    <Box
                        display={"flex"}
                        backgroundColor={expressPrimary}
                        p={2}
                        mb={6}
                        size={"64px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderRadius={"100%"}
                        border={"none"}
                    >
                        <Box mt={1} as={FiTag} size={"30px"} color={textColourWithPrimary} />
                    </Box>
                    <Flex direction={"column"} w={"100%"} alignItems={"flex-start"} ml={4}>
                        <Input
                            bg={expressSecondary}
                            color={textColourWithSecondary}
                            alignItems={"center"}
                            borderRadius={"1.25rem"}
                            _focus={{ borderColor: expressPrimary }}
                            h={"6rem"}
                            px={10}
                            fontSize={"xl"}
                            fullWidth
                            placeholder={translate("discountCode")}
                            value={discountCode}
                            autoFocus={true}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setDiscountCode(event.target.value.trim().toUpperCase())
                            }
                        />

                        <Text ml={2} fontSize={"xs"} color={"red.500"} textAlign={"center"} mt={2} minH={"1.5rem"}>
                            {!!validationStatus ? translate(validationStatus as Languagekey) : ""}
                        </Text>
                    </Flex>
                </Flex>
                <Flex justifyContent={"flex-end"} mt={6}>
                    <ExpressButton
                        justifyContent={"center"}
                        themeColor={expressPrimary}
                        size={"md"}
                        variant="outline"
                        width={"250px"}
                        mr={"16px"}
                        fontWeight={"normal"}
                        children={translate("close")}
                        onClick={() => {
                            closeModal(modalNames.EXPRESS_DISCOUNT_MODAL);
                        }}
                    />
                    <ExpressButton
                        justifyContent={"center"}
                        themeColor={expressPrimary}
                        size={"md"}
                        width={"250px"}
                        variant="solid"
                        isDisabled={!discountCode}
                        children={translate("apply")}
                        onClick={() => {
                            onValidateDiscountCode();
                        }}
                    />
                </Flex>
            </ModalBody>
        </Modal>
    );
};
