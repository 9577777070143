import React from "react";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";

import { ExpressButton } from "../shared/ExpressButton";
import { Box, Flex, FlexProps, Image, Text } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { formatFinancialNumbers, getBasePriceForCartProduct } from "PriceUtils";
import { useOrderWindowsStore, useQoplaStore } from "Stores";
import { ExpressCartContentCardDetails } from "../cart/ExpressCartContentCardDetails";
import { CartProduct } from "Types";
import { TextImageFallback } from "../shared/TextImageFallback";
import { getCartProductType, getCheckoutSummaryModificationPrices } from "../../Utils/expressProductUtils";
import { CART_PRODUCT_TYPE } from "../../Utils/types";
import { ExpressCartContentDiscount } from "../cart/ExpressCartContentDiscount";

type Props = { cartProduct: CartProduct; switchOrientation?: boolean } & FlexProps;

//When we plug in this component, we need to finish add the functions for the buttons & the quantity selector, target orderWindows Store.
export const ExpressCheckoutCartSummaryCard: React.FC<Props> = ({
    cartProduct,
    switchOrientation = false,
    ...props
}) => {
    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();
    const { removeCartProduct } = useOrderWindowsStore();

    const { menuProduct, menuBundleProduct, orderProduct } = cartProduct;
    const { refProduct } = menuProduct || {};
    const { refBundleProduct } = menuBundleProduct || {};
    const imageUrl = refProduct?.imageUrl || refBundleProduct?.imageUrl;
    const totalDefaultPrice = getBasePriceForCartProduct(cartProduct);
    const { name, quantity } = orderProduct;

    const { isPriceModification, priceModification, isAddonPriceModification } =
        getCheckoutSummaryModificationPrices(cartProduct);
    const isPriceModificationTotal = isPriceModification && !!priceModification;

    const cartProductType = getCartProductType(cartProduct, orderProduct.addons.length > 0);
    const hasSimpleCartProduct = [
        CART_PRODUCT_TYPE.SIMPLE_MENU_PRODUCT,
        CART_PRODUCT_TYPE.SIMPLE_MODIFIED_PRODUCT
    ].includes(cartProductType);
    const showDiscountDisplay =
        hasSimpleCartProduct &&
        (orderProduct?.discountValue ?? 0) > 0 &&
        !isPriceModification &&
        !isAddonPriceModification;

    const Img = imageUrl ? (
        <Box w={"112px"} h={"102px"} mr={6}>
            <Image objectFit="cover" src={imageUrl} w={"112px"} h={"102px"} />
        </Box>
    ) : (
        <TextImageFallback
            name={name}
            p={2}
            w={"112px"}
            h={"102px"}
            mr={6}
            truncatedTextProps={{
                width: "112px",
                lines: 5
            }}
        />
    );

    return (
        <Flex flexDirection="column" {...props}>
            <Flex flexDirection="row">
                {Img}
                <Flex flexDirection="column" width="100%">
                    <Flex flexDirection="row" width="100%">
                        <Flex
                            mb={2}
                            fontSize="xl"
                            fontWeight="600"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            width="100%"
                        >
                            <Text m={0} width="50%">
                                {quantity + " x " + name}
                            </Text>

                            <Flex alignItems="flex-start">
                                <ExpressButton
                                    onClick={() => {
                                        removeCartProduct(cartProduct);
                                    }}
                                    size="sm"
                                    variant="outline"
                                    children={translate("delete")}
                                    rightIcon={FaTrash}
                                    marginTop={"-3px"}
                                />
                                {!showDiscountDisplay ? (
                                    <Text width={"120px"} m={0} display="flex" justifyContent={"flex-end"}>
                                        {formatFinancialNumbers(
                                            isPriceModificationTotal ? priceModification : totalDefaultPrice,
                                            companyLocale
                                        )}
                                    </Text>
                                ) : (
                                    <ExpressCartContentDiscount orderProduct={orderProduct} width={"120px"} />
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                    <ExpressCartContentCardDetails
                        orderProduct={orderProduct}
                        isPriceModification={isPriceModification || isAddonPriceModification}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
