import React from "react";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";

import { Box, Flex, NewDivider, Text } from "Atoms";
import { CartProduct, Discount, EatingOption } from "Types";
import { ExpressCheckoutCartSummaryCard } from "./ExpressCheckoutCartSummaryCard";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { ExpressButton } from "../shared/ExpressButton";
import { ExpressScrollBar } from "../shared/ExpressScrollBar";
import { formatFinancialNumbers } from "PriceUtils";
import { useOrderWindowsStore } from "Stores/orderWindowsStore";
import { useQoplaStore } from "Stores/qoplaStore";
import { useModal } from "Providers";
import { modalNames } from "Constants";

type Props = {
    cartProducts: CartProduct[];
    selectedTableNumber: string;
    eatingOption: EatingOption;
    navigateStepForward: () => void;
    discount?: Discount | null;
};

export const ExpressCheckoutSummaryCart: React.FC<Props> = ({
    cartProducts,
    selectedTableNumber,
    eatingOption,
    navigateStepForward,
    discount
}) => {
    const {
        orientation: { isPortrait },
        colors: { expressPrimary, expressMediumGrey }
    } = useTheme();
    const { translate } = useLanguage();
    const { openModal, closeModal } = useModal();

    const { companyLocale, selectedShop } = useQoplaStore();
    const {
        CartManager: { setActiveOrderWindowDiscount }
    } = useOrderWindowsStore();

    const enableDiscounts = selectedShop?.settings?.expressPOSSettings?.enableDiscounts ?? false;

    const onOpenDiscountModal = () => {
        openModal(modalNames.EXPRESS_DISCOUNT_MODAL, {
            eatingOption,
            cartProducts,
            closeModal
        });
    };

    const cartTotalSum = cartProducts.reduce((acc, cartProduct) => acc + cartProduct?.orderProduct?.totalPrice, 0);
    const totalNetAmount = cartProducts.reduce((acc, cartProduct) => acc + cartProduct?.orderProduct?.totalNetPrice, 0);
    const totalDiscountedAmount = cartProducts.reduce(
        (acc, cartProduct) => acc + (cartProduct?.orderProduct?.discountValue ?? 0),
        0
    );
    const vatAmount = cartTotalSum - totalNetAmount;
    const hasDiscountedProducts = totalDiscountedAmount > 0;

    return (
        <Flex width="100%" height="100%" direction={"column"}>
            <Flex justifyContent={"space-between"} alignItems={"center"} h={"auto"} mb={4}>
                <Text
                    whiteSpace="nowrap"
                    ml={1}
                    mb={0}
                    fontSize="3xl"
                    fontWeight="600"
                    width={"max-content"}
                    flexBasis="min-content"
                >
                    {translate("yourOrderExpress")}
                </Text>
                {!!selectedTableNumber && (
                    <Text
                        whiteSpace="nowrap"
                        fontSize="3xl"
                        fontWeight="600"
                        width={"max-content"}
                        flexBasis="min-content"
                        pr={8}
                    >
                        {`${translate("table")}: ${selectedTableNumber}`}
                    </Text>
                )}
            </Flex>
            <NewDivider
                opacity={1}
                color={expressPrimary}
                borderWidth="2px"
                width={isPortrait ? "86vw" : "97%"}
                flexBasis="min-content"
            />
            <ExpressScrollBar
                display="flex"
                flexDirection="column"
                mt="4"
                height="max-content"
                py="4"
                borderRight={`${isPortrait ? "0px" : "25px"} solid #ffffff00 !important`}
            >
                {cartProducts.map((cartProduct, idx) => {
                    return (
                        <Box key={idx}>
                            <Box width="100%" my={0} key={cartProduct.id} color="black" py="4" pr="12">
                                <ExpressCheckoutCartSummaryCard cartProduct={cartProduct} flexBasis="content" />
                            </Box>
                            <NewDivider color={expressMediumGrey} mt={2} />
                        </Box>
                    );
                })}
            </ExpressScrollBar>
            <Flex flexBasis="min-content" flexDirection="column" ml={1} mr={!isPortrait ? 8 : 0}>
                {hasDiscountedProducts ? (
                    <Flex justifyContent="flex-start" fontSize="xl" fontWeight="600" mr="12" mt="8">
                        <Text>{translate("discount")}</Text>
                        <Text m={0} p={0} fontSize={"sm"} ml={2} mt={1} color={"red.500"}>{`(${discount?.name})`}</Text>
                        <ExpressButton
                            rightIcon={FaTrashAlt}
                            size="xs"
                            variant="outline"
                            children={translate("remove")}
                            onClick={() => setActiveOrderWindowDiscount(null, [])}
                            ml={8}
                        />
                        <Text
                            color="red.500"
                            ml="auto"
                        >{`-${formatFinancialNumbers(totalDiscountedAmount, companyLocale)}`}</Text>
                    </Flex>
                ) : null}
                <Flex
                    justifyContent="space-between"
                    fontSize="2xl"
                    fontWeight="600"
                    mr="12"
                    mt={!hasDiscountedProducts ? 12 : 0}
                >
                    <Text>{translate("totalSum")}</Text>
                    <Text>{formatFinancialNumbers(cartTotalSum, companyLocale)}</Text>
                </Flex>
                <Flex justifyContent="space-between" fontSize="xl" mr="12">
                    <Text>{translate("vat")}</Text>
                    <Text>{formatFinancialNumbers(vatAmount, companyLocale)}</Text>
                </Flex>
            </Flex>
            <Flex
                flexBasis="min-content"
                justifyContent={"flex-end"}
                alignItems={"center"}
                my="6"
                mr={!isPortrait ? 12 : 0}
            >
                {enableDiscounts && (
                    <ExpressButton
                        size="md"
                        children={translate("discountCode")}
                        variant="outline"
                        onClick={onOpenDiscountModal}
                    />
                )}
                <ExpressButton
                    disabled={cartProducts.length === 0}
                    themeColor={expressPrimary}
                    size={"md"}
                    children={translate("goToExpressPayment")}
                    onClick={navigateStepForward}
                    mx={3}
                />
            </Flex>
        </Flex>
    );
};
