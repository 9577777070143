import React, { useState } from "react";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";

import { Box, NewDivider, Flex, NewGrid, BaseBoxProps, FlexProps, NewIconButton, NewIconButtonProps } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { ExpressQuantitySelector } from "./ExpressQuantitySelector";
import { Addon, AddonsHashMapItem } from "Types";
import { usePosStore, useQoplaStore } from "Stores";
import { getTextColor } from "Utils";
import { getCurrencySymbol } from "PriceUtils";
import { ExpressScrollBar } from "../shared/ExpressScrollBar";
import { ExpressWrapper } from "../shared/ExpressWrapper";

type Props = {
    productName: string;
    addonGroups: AddonsHashMapItem[];
    selectedAddons: Addon[];
    setSelectedAddons: (addons: Addon[]) => void;
    preSelectedAddons?: Addon[];
    addonProps?: FlexProps;
    scrollProps?: FlexProps;
} & BaseBoxProps;

export const ExpressProductAddons: React.FC<Props> = ({
    productName,
    addonGroups,
    selectedAddons,
    setSelectedAddons,
    addonProps,
    scrollProps,
    ...rest
}) => {
    const [expandedCategories, setExpandedCategories] = useState<string[]>(
        addonGroups.length === 1 ? [addonGroups[0].name] : []
    );

    const {
        colors,
        orientation: { isPortrait }
    } = useTheme();
    const { translateWithArgument, translate } = useLanguage();
    const { companyLocale } = useQoplaStore();
    const { hideFromStockIds, outOfStockIds } = usePosStore();

    const currency = getCurrencySymbol(companyLocale);

    const iconStyles: NewIconButtonProps = {
        rounded: "full",
        type: "button",
        fontSize: isPortrait ? "xl" : "md",
        height: "40px",
        width: "40px",
        color: getTextColor(colors.expressPrimary),
        cursor: "pointer"
    };

    const handleChangeQuantity = (addon: Addon, quantity: number) => {
        const addonIndex = selectedAddons.findIndex(
            selectedAddon => selectedAddon.name === addon.name && selectedAddon.groupId === addon.groupId
        );

        if (addonIndex !== -1) {
            const updatedAddons = [...selectedAddons];
            updatedAddons[addonIndex].quantity += quantity;

            if (updatedAddons[addonIndex].quantity <= 0) {
                updatedAddons.splice(addonIndex, 1);
            }

            setSelectedAddons(updatedAddons);
        } else {
            setSelectedAddons([...selectedAddons, { ...addon, quantity }]);
        }
    };

    const getGroupLimitUsed = (addonGroup: AddonsHashMapItem, selectedAddons: Addon[]): number => {
        const selectedCount = addonGroup.ingredients.reduce((count, ingredientLimiter) => {
            const selectedAddon = selectedAddons.find(
                addon =>
                    addon.name === ingredientLimiter.ingredient.name &&
                    addon.groupId === ingredientLimiter.ingredient.groupId
            );
            return count + (selectedAddon?.quantity ?? 0);
        }, 0);
        return selectedCount;
    };

    const handleSelectAddon = (addon: Addon, selectedAddon: Addon | undefined, groupHasReachedLimit: boolean) => {
        if (outOfStockIds.has(addon.name.toLowerCase())) return;
        if (!!selectedAddon && selectedAddon?.quantity > 0) return;
        if (groupHasReachedLimit) return;

        if (!!selectedAddon) {
            const updatedAddons = selectedAddons.filter(
                _addon => _addon.groupId !== addon.groupId || _addon.name !== addon.name
            );

            setSelectedAddons(updatedAddons);
        } else {
            handleChangeQuantity(addon, 1);
        }
    };

    const sortedAddonGroups = addonGroups.sort((a, b) => a.sortOrder - b.sortOrder);

    return (
        <ExpressWrapper>
            <Flex
                w={isPortrait ? "976px" : "1280px"}
                h={"auto"}
                gridArea={"addons"}
                maxHeight={isPortrait ? "55vh" : "75vh"}
                direction={"column"}
                alignContent={"flex-start"}
                {...rest}
            >
                <Box fontSize={"2rem"} fontWeight={"600"} color={colors.expressTextColor}>
                    {translateWithArgument("chooseOptions", productName)}
                </Box>
                <NewDivider color={colors.expressTextColor} mb={12} />
                <ExpressScrollBar {...scrollProps}>
                    {sortedAddonGroups.map((addonGroup, idx) => {
                        const addons = addonGroup.ingredients;
                        const isCategoryExpanded = expandedCategories.includes(addonGroup.name);
                        const groupLimit = addonGroup.limit;
                        const availableAddons = addons.filter(
                            addon => !hideFromStockIds.has(addon.ingredient.name.toLowerCase())
                        );
                        const shouldDisplayShowMore = availableAddons.length > 4 && !isCategoryExpanded;
                        return (
                            <Box key={addonGroup.name + idx} mb={16}>
                                <Flex alignItems={"center"} mb={2}>
                                    <Box fontSize={"xl"} fontWeight={"600"}>
                                        {addonGroup.name}
                                    </Box>
                                    {groupLimit != 0 && (
                                        <Box fontSize={"sm"} ml={8} pt={1}>
                                            ({translateWithArgument("maxAdonsWithArg", groupLimit)})
                                        </Box>
                                    )}
                                </Flex>
                                <NewDivider color={colors.expressLightGrey} mb={4} />
                                <NewGrid
                                    height="auto"
                                    templateColumns={"1fr 1fr"}
                                    gridTemplateRows={"repeat(auto-fit, minmax(100px, 1fr))"}
                                    style={{ columnGap: "3rem" }}
                                >
                                    {availableAddons.map((addon, idx) => {
                                        const { name, price, groupId } = addon.ingredient;

                                        const isOutOfStock = outOfStockIds.has(name.toLowerCase());
                                        const selectedAddon = selectedAddons.find(
                                            selectedAddon =>
                                                selectedAddon.name === name && selectedAddon.groupId === groupId
                                        );

                                        const shouldShowAddon =
                                            idx <= 3 || expandedCategories.includes(addonGroup.name);
                                        const priceText =
                                            price !== 0 ? `${price > 0 ? "+" : "-"} ${price} ${currency}` : "";
                                        const iconBackGroundColor = isOutOfStock
                                            ? colors.expressLightGrey
                                            : colors.expressPrimary;
                                        const textColor = isOutOfStock
                                            ? colors.expressLightGrey
                                            : colors.expressTextColor;

                                        const groupLimitUsed = getGroupLimitUsed(addonGroup, selectedAddons);
                                        const groupHasReachedLimit =
                                            addonGroup.limit != 0 && groupLimitUsed == addonGroup.limit;
                                        let ingredientLimit = addonGroup.limit != 0 ? addonGroup.limit : addon.limit;
                                        if (groupHasReachedLimit && !!selectedAddon) {
                                            ingredientLimit = selectedAddon.quantity;
                                        }

                                        return (
                                            <Box key={addonGroup.name + name + idx} cursor="pointer">
                                                {shouldShowAddon && (
                                                    <Flex
                                                        justifyContent={"space-between"}
                                                        alignItems={"center"}
                                                        height={"72px"}
                                                        width={isPortrait ? "464px" : "616px"}
                                                        borderBottom={"1px solid"}
                                                        borderColor={colors.expressLightGrey}
                                                        onClick={() => {
                                                            handleSelectAddon(
                                                                addon.ingredient as Addon,
                                                                selectedAddon,
                                                                groupHasReachedLimit
                                                            );
                                                        }}
                                                        {...addonProps}
                                                    >
                                                        <Flex alignItems={"center"} fontSize={"lg"}>
                                                            <Box
                                                                color={textColor}
                                                                textDecoration={isOutOfStock ? "line-through" : "none"}
                                                            >
                                                                {name}
                                                            </Box>
                                                            {isOutOfStock && (
                                                                <Box
                                                                    ml={4}
                                                                    color={colors.expressDarkGrey}
                                                                    textDecoration={"none"}
                                                                >
                                                                    {translate("outOfStock")}
                                                                </Box>
                                                            )}
                                                        </Flex>
                                                        <Flex alignItems={"center"}>
                                                            {priceText && !isOutOfStock && (
                                                                <Box fontSize={"md"} mr={4}>
                                                                    {priceText}
                                                                </Box>
                                                            )}

                                                            {!!selectedAddon ? (
                                                                <ExpressQuantitySelector
                                                                    decrementOrderProduct={() =>
                                                                        handleChangeQuantity(
                                                                            addon.ingredient as Addon,
                                                                            -1
                                                                        )
                                                                    }
                                                                    incrementOrderProduct={() =>
                                                                        handleChangeQuantity(
                                                                            addon.ingredient as Addon,
                                                                            1
                                                                        )
                                                                    }
                                                                    limitQuantity={ingredientLimit}
                                                                    quantity={selectedAddon.quantity}
                                                                />
                                                            ) : (
                                                                <NewIconButton
                                                                    icon={FaPlus}
                                                                    isDisabled={groupHasReachedLimit || isOutOfStock}
                                                                    {...iconStyles}
                                                                    backgroundColor={iconBackGroundColor}
                                                                    _hover={iconBackGroundColor}
                                                                />
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </NewGrid>

                                {shouldDisplayShowMore && (
                                    <Box
                                        fontSize={"xl"}
                                        fontWeight={"600"}
                                        textDecoration={"underline"}
                                        mt={4}
                                        cursor="pointer"
                                        onClick={() => setExpandedCategories([...expandedCategories, addonGroup.name])}
                                    >
                                        {translateWithArgument("showMoreWithArg", addons.length - 4)}
                                    </Box>
                                )}
                            </Box>
                        );
                    })}
                </ExpressScrollBar>
            </Flex>
        </ExpressWrapper>
    );
};
